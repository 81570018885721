import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
  Input,
} from "reactstrap";
import Select from "react-select";
import { programsApi } from "../../../services/programServices";
import makeAnimated from "react-select/animated";
import { usersApi } from "../../../services/userServices";
import InformationModal from "../../InformationModal";

const USER_ROLE_INVESTOR = 3;
const USER_ROLE_STAKEHOLDER = 4;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const StatementsMatchingModal = ({
  defaultDocuments = [],
  onSubmit,
  onClose,
}) => {
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [values, setValues] = useState([]);

  const [programInvestments, setProgramInvestments] = useState([]);

  const [investorsOptions, setInvestorsOptions] = useState([]);
  const [investorsSearch, setInvestorsSearch] = useState("");
  const [investorsLoading, setInvestorsLoading] = useState();

  const [programsOptions, setProgramsOptions] = useState([]);
  const [programsSearch, setProgramsSearch] = useState("");
  const [programsLoading, setProgramsLoading] = useState();

  useEffect(() => {
    if (defaultDocuments) {
      setValues(defaultDocuments);
    }
  }, [defaultDocuments]);

  useEffect(() => {
    setProgramsLoading(true);
    programsApi
      .getAllPrograms({ pageSize: 999, search: programsSearch })
      .then((programs) => {
        const programOptions = programs?.data?.map((program) => {
          return { label: program.name, value: program.id };
        });
        setProgramsOptions(programOptions);
        const programInvestments = programs.data.flatMap((p) =>
          p.programInvestments.map((pi) => ({
            ...pi,
            programId: p.id,
          }))
        );
        setProgramInvestments(programInvestments);
        setProgramsLoading(false);
      });
  }, [programsSearch]);

  useEffect(() => {
    setInvestorsLoading(true);
    Promise.all([
      usersApi.getUsers(
        {
          role: USER_ROLE_INVESTOR,
          search: investorsSearch,
          page: 0,
          pageSize: investorsSearch.length >= 2 ? Number.MAX_SAFE_INTEGER : 10,
        },
        "admin"
      ),
      usersApi.getUsers(
        {
          role: USER_ROLE_STAKEHOLDER,
          search: investorsSearch,
          page: 0,
          pageSize: investorsSearch.length >= 2 ? Number.MAX_SAFE_INTEGER : 10,
        },
        "admin"
      ),
    ]).then(([stakeholders, investors]) => {
      const investorsOptions = [
        ...stakeholders.data.map((stakeholder) => {
          return { label: stakeholder.name, value: stakeholder.id };
        }),
        ...investors?.data?.map((investor) => {
          return { label: investor.name, value: investor.id };
        }),
      ];
      setInvestorsOptions(investorsOptions);
      setInvestorsLoading(false);
    });
  }, [investorsSearch]);

  const onInvestorChange = (index, investor) => {
    if (investor && investor.value) {
      let vals = [...values];
      const currentValue = values[index];
      const newValue = {
        ...currentValue,
        investor,
      };
      vals.splice(index, 1, newValue);
      setValues(vals);
    }
  };

  const onProgramChange = (index, program) => {
    if (program && program.value) {
      let vals = [...values];
      const currentValue = values[index];
      const newValue = {
        ...currentValue,
        program,
      };
      vals.splice(index, 1, newValue);
      setValues(vals);
    }
  };

  const onYearChange = (index, year) => {
    let vals = [...values];
    const currentValue = values[index];
    const newValue = {
      ...currentValue,
      year,
    };
    vals.splice(index, 1, newValue);
    setValues(vals);
  };

  const onQuarterChange = (index, quarter) => {
    let vals = [...values];
    const currentValue = values[index];
    const newValue = {
      ...currentValue,
      quarter,
    };
    vals.splice(index, 1, newValue);
    setValues(vals);
  };

  const doSubmit = (values) => {
    const error = values.find(
      (v) => !v.investor || !v.program || !v.quarter || !v.year
    );
    if (error) {
      setInformationModal({
        isOpen: true,
        title: "Statements Documents Matching",
        body: `Please fill all the entries for ${error.fileName}.`,
      });
    } else {
      onSubmit(values);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <ModalHeader close={closeBtn} className="text-body">
        <div className="d-flex flex-column">Statements Documents Matching</div>
      </ModalHeader>
      <ModalBody className="text-center">
        {
          <Table striped className="text-custom-dark text-left col-12 small">
            <thead className="col-12">
              <tr>
                <th style={{ width: "30%" }}>Filename</th>
                <th style={{ width: "20%" }}>Program</th>
                <th style={{ width: "15%" }}>Year</th>
                <th style={{ width: "15%" }}>Quarter</th>
                <th style={{ width: "20%" }}>Investor</th>
              </tr>
            </thead>
            <tbody className="col-12">
              {values.map((value, i) => {
                if (
                  investorsOptions.length &&
                  value.investor?.label &&
                  !value.investor?.value
                ) {
                  const investorsOption = investorsOptions.find(
                    (io) => io.label === value.investor.label
                  );
                  if (investorsOption) {
                    value.investor.value = investorsOption.id;
                  }
                }
                return (
                  <tr key={i}>
                    <td style={{ width: "30%" }}>
                      {value.filename || value.fileName}
                    </td>
                    <td style={{ width: "20%" }}>
                      <Select
                        placeholder={
                          <span className="text-muted">Program..</span>
                        }
                        noOptionsMessage={() => "No programs found"}
                        styles={style}
                        className="col-12 px-0 border rounded my-2"
                        options={programsOptions.filter((program) => {
                          const investor = values[i]?.investor;
                          const investorInvested = programInvestments.find(
                            (pi) =>
                              pi.programId === program.value &&
                              pi.investor.name === investor?.label
                          );
                          return investor ? investorInvested : true;
                        })}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={value.program}
                        isSearchable
                        inputValue={programsSearch}
                        onInputChange={(value) => setProgramsSearch(value)}
                        onChange={(selected) => onProgramChange(i, selected)}
                        isLoading={programsLoading}
                      />
                    </td>
                    <td style={{ width: "15%" }}>
                      <Input
                        style={{ height: "38px" }}
                        value={value.year || ""}
                        onChange={(evt) =>
                          onYearChange(i, evt.currentTarget.value)
                        }
                        type="number"
                        min="2000"
                        max="9999"
                        placeholder="Year.."
                      />
                    </td>
                    <td style={{ width: "15%" }}>
                      <Select
                        value={value.quarter || ""}
                        onChange={(selected) => onQuarterChange(i, selected)}
                        options={[
                          { value: 1, label: 1 },
                          { value: 2, label: 2 },
                          { value: 3, label: 3 },
                          { value: 4, label: 4 },
                        ]}
                        placeholder="Quarter.."
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <Select
                        placeholder={
                          <span className="text-muted">Investor..</span>
                        }
                        noOptionsMessage={() => "No investors found"}
                        styles={style}
                        className="col-12 px-0 border rounded my-2"
                        options={investorsOptions}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={value.investor}
                        isSearchable
                        inputValue={investorsSearch}
                        onInputChange={(value) => setInvestorsSearch(value)}
                        onChange={(selected) => onInvestorChange(i, selected)}
                        isLoading={investorsLoading}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        }
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>
        <Button color={"primary"} onClick={() => doSubmit(values)}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StatementsMatchingModal;
